import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import ProjectSeries from "../components/ProjectSeries"
import CurrentProject from "../components/CurrentProject"
import ImageGallery from "../components/ImageGallery"

import Img from "../images/project_thumbnails/scara_webapp.png"

const imgs = [
    {
        img: Img,
        alt: "Early Version of webapp interface",
    },
]

const SaraWebappPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>SCARA Webapp</h1>
            {CurrentProject()}
            {ProjectSeries({
                name: "Scara Arm",
                nextname: "Scara",
                nextlink: "/scara",
                prevname: "Scara",
                prevlink: "/scara",
            })}
            <p><strong>Very Early Stages</strong></p>
            <p>Bulding on the concepts from <a href="/solidworks">SolidWorks Webapp</a>, I am developing a Django REST React webapp for interfacing with the Four Degree of Freedom SCARA Arm I designed and built.</p>
            <p>Since the SCARA Arm runs on Marlin, this is in effect a knock off version of <a href="https://octoprint.org/">OctoPrint</a>, a software for controlling 3D printers remotely. However, I plan on adding additional features, such as keypoints and the ability to return there with only one click.</p>
            <p>Very early stages, but I have demonstrated the ability to connect Marlin (via Serial (via USB to UART)) into Django and then onwards to a web interface. More updates to come.</p>
            <hr/>
            <ImageGallery value={imgs}/>
            {FooterBar()}
        </div>
    )
}

export default SaraWebappPage;