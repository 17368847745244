import DisclaimerBase from "./DisclaimerBase"


function CurrentProject(prop) {
    return (
        DisclaimerBase({
            s: "Current Project Disclaimer",
            p: "This is an ongoing project. Check back later for a more complete discussion.",
        })
    )
}

export default CurrentProject